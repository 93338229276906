import(/* webpackMode: "eager" */ "/app/jetlend_react_lib/packages/jetlend-web-ui/src/components/Wizard/wizardBreadCrumbButton.module.scss");
;
import(/* webpackMode: "eager" */ "/app/jetlend_react_lib/packages/jetlend-web-ui/src/ui/inputs/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/jetlend_react_lib/packages/jetlend-web-ui/src/ui/TooltipIcon/Tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/(main)/investor/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/client/aboutPlatform/DirectorsSection/DirectorsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/client/aboutPlatform/EmployeeDepartmentBlock/EmployeeDepartmentBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/client/borrowers/BecomeBorrowerForm/BecomeBorrowerForm.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/client/borrowers/BorrowersCalculatorSection/BorrowersCalculatorSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/client/documents/DocumentsBlock/DocumentsBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/client/investors/BecomeInvestorForm/BecomeInvestorForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/client/investors/InvestmentCalculator/InvestmentCalculator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/client/investors/InvestorsMainSection/InvestorsMainSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/client/partners/BecomePartnerForm/BecomePartnerForm.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/client/partners/PartnerCalculatorSection/PartnerCalculatorSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/server/aboutPlatform/FeaturesSection/PlatformFeature/PlatformFeature.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/server/academy/AcademyNewsSection/AcademyNewsSlider/AcademyNewsSlider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/borrowers/BorrowersFeaturesSection/BorrowersFeatures.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/borrowers/BorrowersFeaturesSection/SmallBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/common/BaseFaqSection/FaqItem.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/common/ExternalNewsSection/ExternalNewsItem.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/borrowers/BorrowersFeaturesSection/LargeBlock.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/borrowers/BorrowersConditionsSection/BorrowersConditionsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/aboutPlatform/EmployeeSection/EmployeeSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/aboutPlatform/FeaturesSection/AdvantagesFeature/AdvantagesFeature.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/aboutPlatform/FeaturesSection/BenefitsFeature/BenefitsFeature.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/aboutPlatform/FeaturesSection/FeaturesSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/aboutPlatform/MarkupSection/MarkupSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/academy/AcademyFaqSection/AcademyFaqSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/academy/AcademyNewsSection/AcademyNewsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/common/FeedbackSection/FeedbackSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/server/common/HowItWorksSection/HowItWorksSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/common/PartnersSection/PartnersSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/server/common/YoutubeSection/YouTubeItem.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/documents/DocumentsSection/DocumentsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/common/StatisticsSection/StatisticsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/investors/ConditionsSection/ConditionsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/investors/InvestorsChartSection/InvestorsChartSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/investors/InvestorsCommonSlideSection/InvestorsCommonSlideSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/server/investors/InvestorsCommonSlideSection/SlideLink/SlideLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/server/investors/InvestorsFeaturesSection/InvestorsFeaturesSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/investors/InvestorsGeographySection/InvestorsGeographySection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/server/investors/InvestorsMarkupSection/ExpandControl/ExpandControl.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/server/mainPage/MainPageFeaturesSection/MainPageFeaturesSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/partners/PartnersConditionsSection/PartnersConditionsSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/investors/InvestorsMarkupSection/InvestorsMarkupSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/news/NewsCard/NewsCard.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/server/partners/PartnersFeaturesSection/PartnersFeaturesSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/server/ui/Section/Section.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/server/ui/SectionPart/SectionPart.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/icons/app-store-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/google-play-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/yandex-icon.svg");
