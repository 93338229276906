import { mergeClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import FormAchievement from './FormAchievment/FormAchievement';
import styles from './FormAchievements.module.scss';
import { AchievementType } from './FormAchievment/FormAchievement.helpers';


interface IProps {
    className?: string;
}

const FormAchievements : React.FC<IProps> = props => {
    const { className } = props;
    return (
        <div className={mergeClassNames([styles['achievements'], className])}>

            <FormAchievement
                className={styles['achievement']}
                achievement={AchievementType.NUMBER_ONE}
                wide
            />
            <FormAchievement
                className={styles['achievement']}
                achievement={AchievementType.SKOLKOVO}
                wideIcon
                skolkovo
            />
            <FormAchievement
                className={styles['achievement']}
                achievement={AchievementType.RATING}
                wideIcon
            />
            <FormAchievement
                className={styles['achievement']}
                achievement={AchievementType.CENTRAL_BANK}
            />
        </div>
    );
};

export default FormAchievements;

