'use client';

import {
    useCallback,
    useState,
} from 'react';
import {
    useWindowSize,
    isMobile,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import styles from './InvestorsMainSection.module.scss';
import Section from '../../../server/ui/Section/Section';
import Image from '../../../server/Image/Image';
import BecomeInvestorForm from '@app/components/client/investors/BecomeInvestorForm/BecomeInvestorForm';
import HtmlMarkup from '@app/components/server/common/HtmlMarkup/HtmlMarkup';
import JivoChatMobileAnimation from '../../common/JivoChatMobileAnimation/JivoChatMobileAnimation';
import FormAchievements from '../../common/FormAchievements/FormAchievements';

interface IProps {
    title: string;
    description: string;
}

export default function InvestorsMainSection  ({
    title,
    description,
}: IProps) {
    const [loaded, setLoaded] = useState(false);
    const { width } = useWindowSize();

    const isMobileSize = isMobile(width);

    const didLoaded = useCallback(() => {
        setLoaded(true);
    }, []);

    return (
        <>
            <JivoChatMobileAnimation />
            <div className={styles['mobile-view']}>
                <Section
                    stretch={['horizontally', 'bottom']}
                    animation="none"
                >
                    <div className={styles['container']}>
                        <div className={styles['mobile-header']}>
                            <HtmlMarkup tag="div" className={styles['form__heading']} markup={title} />
                            <FormAchievements className={styles['achievements']} />
                        </div>
                        <div className={styles['image-wrapper--mobile']}>
                            <Image
                                src="/assets/ruble.webp"
                                alt=""
                                fill
                                priority
                                className={styles['test']}
                                onLoad={didLoaded}
                                quality={loaded ? 40 : 1}
                            />
                        </div>
                        <div className={styles['mobile-button-wrapper']}>
                            <BecomeInvestorForm type="button" />
                        </div>
                    </div>
                </Section>
            </div>

            {!isMobileSize && (
                <div className={styles['desktop-view']}>
                    <Section
                        animation="none"
                    >
                        <div className={styles['container']}>
                            <div className={styles['form']}>
                                <HtmlMarkup tag="h1" className={styles['form__heading']} markup={title} />
                                <div className={styles['form__subsection']}>
                                    <HtmlMarkup tag="p" className={styles['form__description']} markup={description} />
                                    <div className={styles['form__input']}>
                                        <div className={styles['form__input--short']}>
                                            <BecomeInvestorForm
                                                type="short"
                                            />
                                        </div>
                                        <div className={styles['form__input-button']}>
                                            <BecomeInvestorForm
                                                type="button"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormAchievements className={styles['achievements']} />
                            <div className={styles['image-wrapper']}>
                                <Image
                                    src="/assets/investicionnaja-platforma-jetlend.webp"
                                    alt="инвестиционная платформа Jetlend"
                                    title="инвестиционная платформа Jetlend"
                                    fill
                                    sizes="(max-width: 950px) 400px, (max-width: 1250px) 500px, (max-width: 1650px) 700px, 900px"
                                    onLoad={didLoaded}
                                    quality={loaded ? 100 : 10}
                                    priority
                                />
                            </div>
                        </div>
                    </Section>
                </div>
            )}
        </>
    );
};
